import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Row, Col, Button, Checkbox, DatePicker, message, Drawer, Spin, Tooltip } from 'antd'
import DownIconGray from '../../../../images/DownIconGray.svg'
import UpIconGray from '../../../../images/UpIconGray.svg'
import { InfoCircleFilled } from '@ant-design/icons'
import { scheduleTimeService } from '../../../../services/scheduleTime'
import moment from 'moment'
import 'moment/locale/es'
import locale from 'antd/es/date-picker/locale/es_ES'
import humanizeList from 'humanize-list'
import { uniq, map, groupBy, flattenDeep } from 'lodash'
import { useTranslation } from "react-i18next"
import { documentTypeName } from '../../../../helpers/handlers'

const { format } = require('rut.js')

const confirmChangeDateText = `Solo es posible hacer una reserva para una sola fecha. ¿Deseas continuar?`
const sundayNumber = 6

const SelectDateBlock = ({
  branchOffice,
  scheduleState,
  cartState,
  setCart,
  queryParams,
}) => {
  const [dataBlocks, setDataBlocks] = useState();
  const [block, setBlock] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState({});
  const [showPatients, setShowPatients] = useState(false);
  const [selectionPatients, setSelectionPatients] = useState([]);
  const [patientsByBlock, setPatientsByBlock] = useState();
  const [selectionBlock, setSelectionBlock] = useState();
  const [selectionDate, setSelectionDate] = useState();
  const [canContinue, setCanContinue] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [department, setDepartment] = useState(false);
  const [initialDate, setInitialDate] = useState();
  const patientsRef = useRef();
  const { t } = useTranslation();


  const fetchDateBlocks = async (params, isFirstTime) => {
    setLoading(true)
    try {
      if (moment(params.date).weekday() === sundayNumber && isFirstTime) {
        params['date'] = moment(params.date).add(1, 'day').format('YYYY-MM-DD');
      }

      const batteries = map(flattenDeep(map(cartState.patients, 'services')).filter(item => item.type === "Battery"), 'id').join(',');
      if (batteries) {
        params['batteries'] = batteries;
      }

      const exams = map(flattenDeep(map(cartState.patients, 'services')).filter(item => item.type === "Exam"), 'id').join(',');
      if (exams) {
        params['exams'] = exams;
      }
      let response = await scheduleTimeService.dateBlocks(params);

      if (response?.blocks?.filter(block => block.available === true).length === 0 && isFirstTime) {
        const newDate = moment(params.date, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
        response = await scheduleTimeService.dateBlocks({ ...params, date: newDate })
      }

      if (params.date === moment().format('YYYY-MM-DD')) {
        response?.blocks?.forEach((block) => {
          const hour = parseInt(block.block?.split(':')[0], 10);
          block.available = parseInt(moment().format('HH'), 10) < hour;
        });
      }

      setDataBlocks(response);
      setSelectionDate(response.date);
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al obtener hora y fechas disponibles`,
        style: {
          marginTop: '45vh'
        }
      })
    } finally {
      setLoading(false);
    }
  }

  const getDepartment = () => {
    const departments = uniq(map(cartState.patients, (patient) => {
      return map(patient.services, 'department_id')
    }))

    if (uniq(departments[0]).length > 1) {
      const patients = cartState.patients.filter((patient) => {
        return uniq(patient.services.map((service) => {
          return service.department_id
        })).length > 1
      });
      return `patients:${patients.length}`
    }

    return departments[0][0]
  };


  const initialInstance = useCallback(async () => {
    let date;
    if (queryParams?.date && branchOffice?.id !== 18) {
      date = queryParams.date;
    } else {
      date = moment().format('YYYY-MM-DD')
    }

    if (cartState?.patients?.length) {
      await executeDateBlocks(date);
    }

    setInitialDate(date);
  }, [initialDate]);

  useEffect(() => {
    initialInstance();
  }, [initialInstance])

  useEffect(() => {
    groupingPatients(cartState.patients);
  }, [JSON.stringify(cartState.patients)]);

  useEffect(() => {
    checkPatientsBlockAndDate();
  }, [dataBlocks, patientsByBlock])

  const executeDateBlocks = async (date) => {
    const currentDepartment = getDepartment();
    await fetchDateBlocks({ date, branchOffice: branchOffice.id, department: currentDepartment }, true);
    groupingPatients(cartState.patients);
    setDepartment(currentDepartment);
  }

  const cleanPatientsBlock = () => {
    setCart(state => ({
      ...state,
      patients: state.patients.map(patient => ({
        ...patient,
        date: null,
        block: null,
        block_id: null,
      }))
    }));
  }

  const checkEmptyPatientsByBlock = () => {
    return patientsByBlock == null || Object.keys(patientsByBlock).length === 0
  }

  const handleDayBefore = async () => {
    if ((checkEmptyPatientsByBlock()) || (Object.keys(patientsByBlock).length && window.confirm(confirmChangeDateText))) {
      const dayBefore = moment(selectionDate, 'YYYY-MM-DD').subtract(1, 'day')
      await fetchDateBlocks({ date: dayBefore.format('YYYY-MM-DD'), branchOffice: branchOffice.id, department }, false)
      cleanPatientsBlock()
      setPatientsByBlock()
      setSelectionBlock()
      setSelectionPatients([])
    }
  }

  const handleNextDay = async () => {
    if ((checkEmptyPatientsByBlock()) || (Object.keys(patientsByBlock).length && window.confirm(confirmChangeDateText))) {
      const nextDay = moment(selectionDate, 'YYYY-MM-DD').add(1, 'day')
      await fetchDateBlocks({ date: nextDay.format('YYYY-MM-DD'), branchOffice: branchOffice.id, department }, false)
      cleanPatientsBlock()
      setPatientsByBlock()
      setSelectionBlock()
      setSelectionPatients([])
    }
  }

  const disabledDate = (current) => {
    return (current && current < moment(initialDate, 'YYYY-MM-DD')) || (current && current.weekday() === sundayNumber)
  }

  const handleDatePicker = async (date, dateString) => {
    const text = `Sólo es posible reservar una misma fecha para todos los ${t('scheduleTime.selectDateBlock.patientsTitle')}. ¿Deseas cambiar la fecha?`
    if ((checkEmptyPatientsByBlock()) || (Object.keys(patientsByBlock).length && window.confirm(text))) {
      const pickedDate = moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD')
      await fetchDateBlocks({ date: pickedDate, branchOffice: branchOffice.id, department }, false)
      setSelectionDate(pickedDate)
      cleanPatientsBlock()
      setPatientsByBlock()
      setSelectionBlock()
      setSelectionPatients([])
    }
  }

  const formatDate = (date) => {
    let format = moment(date, 'YYYY-MM-DD').locale("es").format('dddd, DD [de] MMMM [de] YYYY')
    const splitDate = format.split(",")
    const lowerCaseDayName = splitDate[0].toLowerCase()
    const capitalizeDayName = lowerCaseDayName.replace(/\b(\w)/g, s => s.toUpperCase())
    const dateFormat = splitDate[1]
    return `${capitalizeDayName}, ${dateFormat}`
  }

  const calculatePatientsPercentage = () => {
    const patients = cartState.patients;
    const withBlockAndDate = patients.filter(patient => patient.date && patient.block).length;
    const totalPatients = patients.length;
    return Math.round((withBlockAndDate / totalPatients) * 100);
  };

  const handleSetBlock = (block) => {
    const items = cartState.patients
    const checkWithoutBlock = items.filter(item => item.block === null).length > 0
    if (!checkWithoutBlock) {
      message.error({
        content: `Ya has asignado hora a todos tus ${t('scheduleTime.selectDateBlock.patientsTitle')}`,
        style: {
          marginTop: '45vh'
        }
      })
    } else {
      setBlock(block);
      setSelectedBlock(block);
      setSelectionBlock(block);
      setShowPatients(true);
      titleAndStepsToHideWithSwitch.current.classList.add('hide-steps')
      patientsQuantityToHideWithSwitch.current.classList.add('hide-patients-quantity')
      patientsPercentageToHideWithSwitch.current.classList.add('hide-patients-percentage')
      blockToHideWithSwitch.current.classList.add('hide-block')
      hourSelectionToHideWithSwitch.current.classList.add('hide-hour-selection')
    }
  }

  const handleCheckbox = (isChecked, block, patientNumber) => {
    setSelectionBlock(block)
    if (isChecked) {
      setSelectionPatients(selectionPatients => [...selectionPatients, patientNumber])
    } else {
      const filterPatients = selectionPatients.filter(patient => patient !== patientNumber)
      setSelectionPatients([...filterPatients])
    }
  }

  const handleSelectTime = () => {
    if (!selectionPatients.length) {
      patientsRef.current.classList.add('animate__headShake');
      return;
    }

    patientsRef.current.classList.remove('animate__headShake');
    const patientsWithSelectedBlock = cartState.patients.filter(patient => patient.block === selectedBlock.block).length;
    const blockCapacity = selectedBlock.max_capacity;
    if ((patientsWithSelectedBlock + selectionPatients.length) > blockCapacity) {
      message.error({
        content: `Máximo de ${t('scheduleTime.selectDateBlock.patientsTitle')} superado. Por favor, selecciona otro horario.`,
        style: {
          marginTop: '40vh'
        }
      });
      return;
    } else {
      const block = selectionBlock;
      const selectedPatients = [...selectionPatients];
      setCart(state => {
        const patients = [...state.patients];
        selectedPatients.forEach(patientNumber => {
          patients[patientNumber].date = selectionDate;
          patients[patientNumber].block = block.block;
          patients[patientNumber].block_id = block.id;
        });
        return {
          ...state,
          patients,
        }
      });
      setSelectedBlock({});
      setShowPatients(false);
      setSelectionPatients([]);
      titleAndStepsToHideWithSwitch.current.classList.remove('hide-steps');
      patientsQuantityToHideWithSwitch.current.classList.remove('hide-patients-quantity');
      patientsPercentageToHideWithSwitch.current.classList.remove('hide-patients-percentage');
      blockToHideWithSwitch.current.classList.remove('hide-block');
      hourSelectionToHideWithSwitch.current.classList.remove('hide-hour-selection');
    }
  }

  const groupingPatients = (patients) => {
    setPatientsByBlock(groupBy(patients.filter(patient => patient.date !== null && patient.block !== null), 'block'));
  }

  const handleCancelPatient = (number) => {
    setCart(state => {
      const patients = [...state.patients];
      patients[number].date = null;
      patients[number].block = null;
      patients[number].block_id = null;
      return {
        ...state,
        patients,
      }
    });
  }


  const checkPatientsBlockAndDate = () => {
    const checkPatients = cartState.patients.filter(patient => !patient.block && !patient.date)
    setCanContinue(checkPatients.length === 0)
  }

  const handleChangeStateNext = () => {
    if (canContinue) {
      scheduleState(3);
      if (window.hj) {
        window.hj('event', 'reservations-confirmation')
      }
    }
  }

  const titleAndStepsToHideWithSwitch = useRef()
  const patientsQuantityToHideWithSwitch = useRef()
  const patientsPercentageToHideWithSwitch = useRef()
  const blockToHideWithSwitch = useRef()
  const hourSelectionToHideWithSwitch = useRef()

  const handleChangeChevron = () => {
    if (isDrawerVisible) {
      setIsDrawerVisible(false)
    } else {
      setIsDrawerVisible(true)
    }
  }

  const onCloseDrawer = () => {
    setIsDrawerVisible(false)
  }

  const isNotCompleted = () => {
    const withoutBlock = cartState.patients.filter(patient => patient.date !== null && patient.block !== null).length
    return withoutBlock < cartState.patients.length
  }

  const calcPatientsWithoutBlocks = () => {
    const withoutBlock = cartState.patients.filter(patient => patient.date !== null && patient.block !== null).length
    return cartState.patients.length - withoutBlock
  }

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      for (let patient of cartState.patients.filter(patient => patient.block === null && patient.date === null)) {
        setSelectionPatients(selectionPatients => [...selectionPatients, patient.number])
      }
    } else {
      setSelectionPatients([])
    }
  }

  return (
    <Spin className='cmt-spin' spinning={loading}>
      <Row justify="center" className="select-date-block-container">
        <Col lg={22} md={24} sm={24} xs={24}>
          <Row justify="center" ref={titleAndStepsToHideWithSwitch}>
            <Col lg={23} xs={22} className="mt-50">
              <h2 className="text-center">{t('scheduleTime.selectDateBlock.reservationTitle')}</h2>
            </Col>
          </Row>
          <Row
            justify="center"
            className="mt-30 mb-lg-170 mb-md-110 mb-sm-170 mb-xs-180"
            gutter={{ lg: 30, md: 0, sm: 0, xs: 0 }}
          >
            <Col lg={17} xs={22}>
              <Row justify="space-between">
                <Col lg={24} xs={24} className="select-block-wrapper">
                  <Row>
                    <Col lg={23} ref={patientsQuantityToHideWithSwitch}>
                      {
                        calcPatientsWithoutBlocks() === 0 ?
                          <p className="mb-10">
                            Ya has seleccionado hora para todos los {t('scheduleTime.selectDateBlock.patientsTitle')}.
                          </p>
                          :
                          <p className="mb-10">
                            {t('scheduleTime.selectDateBlock.selectPatientsText1')}&nbsp;
                            {calcPatientsWithoutBlocks()}&nbsp;
                            {
                              calcPatientsWithoutBlocks() === 1 ?
                                `${t('scheduleTime.selectDateBlock.patientTitle').toLocaleLowerCase()}.`
                                :
                                t('scheduleTime.selectDateBlock.selectPatientsText2')
                            }
                          </p>
                      }
                    </Col>
                    <Col lg={24} xs={24} ref={patientsPercentageToHideWithSwitch}>
                      <Row justify="center" className="usericon-border-row pt-5 pb-5">
                        <Col lg={22}>
                          <Row className="usericon-percentage-wrapper">
                            {
                              cartState.patients.map((patient, i) => {
                                return (
                                  <Col className="mr-15" key={i}>
                                    <span className={patient.date ? 'icon-system-user' : 'icon-system-user opaque'} />
                                  </Col>
                                )
                              })
                            }
                            <Col>
                              <p className="mb-0">{calculatePatientsPercentage()}% completado</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24} ref={blockToHideWithSwitch}>
                      <Row justify="center" className="date-block-row pt-20 pb-20 mt-15 mb-15">
                        <Col lg={{ order: 1, span: 22 }} xs={{ order: 1, span: 22 }}>
                          {
                            dataBlocks &&
                            <h3 className="lower-case">{formatDate(dataBlocks.date)}</h3>
                          }
                        </Col>
                        <Col lg={{ order: 2, span: 22 }} xs={{ order: 3, span: 22 }} className="mt-15">
                          <Row>
                            {
                              dataBlocks && dataBlocks.blocks.map((data, index) => {
                                return data.available ?
                                  <Col lg={2} md={2} sm={3} xs={5} key={index} className="enabled-block mr-10">
                                    <Row justify="center">
                                      <Col lg={24} xs={24}>
                                        {
                                          selectedBlock.block === data.block ?
                                            <Button className="selected-button" onClick={() => handleSetBlock(data)} block>{data.block}</Button>
                                            :
                                            <Button onClick={() => handleSetBlock(data)} block>{data.block}</Button>
                                        }
                                      </Col>
                                      <Col lg={24} xs={24} className="mt-10">
                                        <p className="text-center">{data.max_capacity} cupos</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  :
                                  <Col lg={2} md={2} sm={3} xs={5} key={index} className="disabled-block mr-10">
                                    <Row justify="center">
                                      <Col lg={24} xs={24}>
                                        <Button disabled={true} block>{data.block}</Button>
                                      </Col>
                                      <Col lg={22} xs={24} className="mt-10">
                                        <p className="text-center">{t('scheduleTime.selectDateBlock.timeNotAvailableText')}</p>
                                      </Col>
                                    </Row>
                                  </Col>
                              })
                            }
                          </Row>
                        </Col>
                        {
                          initialDate && selectionDate && selectionDate > initialDate && (
                            <Col lg={{ order: 3, span: 6 }} xs={{ order: 4, span: 12 }}>
                              <Row>
                                <Col>
                                  <Button
                                    className="select-next-day-button pr-lg-0 pl-lg-0 pr-xs-10 pl-xs-10"
                                    onClick={handleDayBefore}
                                  >
                                    <Row gutter={5}>
                                      <Col lg={2}>
                                        <span className="icon-system-chevron-left" />
                                      </Col>
                                      <Col lg={22}>
                                        <p>VER DÍA ANTERIOR</p>
                                      </Col>
                                    </Row>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          )
                        }

                        <Col lg={{ order: 4, span: 10 }} xs={{ order: 2, span: 22 }}>
                          <Row justify="center" className="select-date-picker-container">
                            <Col lg={15} xs={24}>
                              <Row justify="center" className="select-date-picker-wrapper">
                                <Col lg={22} md={8} sm={7} xs={12}>
                                  <DatePicker
                                    inputReadOnly
                                    locale={locale}
                                    className="select-date-picker pt-0 pb-0 pl-lg-10 pr-lg-10 pl-md-0 pl-sm-0 pl-xs-0 pr-xs-0"
                                    bordered={false}
                                    disabledDate={disabledDate}
                                    onChange={handleDatePicker}
                                    placeholder="SELECCIONAR FECHA"
                                    style={{ width: '100%', minWidth: 165, maxWidth: 175 }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={{ order: 5, span: 6 }} xs={{ order: 5, span: 12 }}>
                          <Row justify="end">
                            <Col>
                              <Button
                                className="select-next-day-button pr-lg-0 pl-lg-0 pr-xs-10 pl-xs-10"
                                onClick={handleNextDay}
                              >
                                <Row justify="end" gutter={5}>
                                  <Col lg={22}>
                                    <p>VER PRÓXIMO DÍA</p>
                                  </Col>
                                  <Col lg={2}>
                                    <span className="icon-system-chevron-right" />
                                  </Col>
                                </Row>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    {
                      block && dataBlocks && showPatients &&
                      <Col lg={24}>
                        <Row justify="center" className="select-patients-wrapper pt-30 pb-70">
                          <Col lg={21} xs={21}>
                            <h3 className="lower-case">{formatDate(dataBlocks.date)} - {block.block} hs</h3>
                          </Col>
                          <Col lg={21} xs={21} className="mt-10">
                            <p>{t('scheduleTime.selectDateBlock.selectPatientsText3')}</p>
                          </Col>
                          <Col lg={21} xs={21}>
                            <Row ref={patientsRef} className="animate__animated">
                              <Col lg={17} xs={24}>
                                {cartState.patients && cartState.patients.length > 1 &&
                                  <Row>
                                    <Col lg={20} md={20} sm={22} xs={24}>
                                      <div className="select-all-patients">
                                        Seleccionar todos
                                        <Checkbox onChange={handleSelectAll} className="cmt-checkbox mr-10" />
                                      </div>
                                    </Col>
                                  </Row>
                                }

                                {
                                  cartState.patients && cartState.patients.filter(patient => patient.block === null && patient.date === null).map((data, index) => {
                                    return <Row key={index}>
                                      <Col lg={20} md={20} sm={22} xs={24} className="patient-gray-row pt-10 pb-10 mt-10">
                                        <Row justify="space-around">
                                          <Col lg={19} xs={19}>
                                            <Row>
                                              <Col>
                                                <p className="mb-0">{t('scheduleTime.selectDateBlock.patientTitle')} {data.number + 1}</p>
                                              </Col>
                                              <Col className="info-icon-wrapper ml-10">
                                                <Tooltip
                                                  color='#223A70'
                                                  title={
                                                    humanizeList(
                                                      data.services.map(service => {
                                                        return service.battery_name
                                                      }),
                                                      { conjunction: 'y' }
                                                    )
                                                  }
                                                >
                                                  <InfoCircleFilled />
                                                </Tooltip>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col lg={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Checkbox
                                              checked={selectionPatients && selectionPatients.includes(data.number)}
                                              onChange={(event) => handleCheckbox(event.target.checked, block, data.number)}
                                              className='cmt-checkbox'
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  })
                                }
                              </Col>
                              <Col lg={7} md={20} sm={22} xs={24} className="mt-lg-0 mt-md-40 mt-sm-40 mt-xs-40" style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                <Button
                                  className='cmt-button secondary'
                                  type='primary'
                                  onClick={handleSelectTime}
                                  block
                                  size='large'
                                >
                                  Seleccionar hora
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    }

                    {
                      !showPatients && isNotCompleted() && (
                        <Col lg={24} xs={24}>
                          <Row justify="center" align="middle" style={{ height: 100 }}>
                            <Col lg={24} xs={24}>
                              <p className="patients-without-block text-center">
                                {t('scheduleTime.selectDateBlock.selectPatientsText4')}&nbsp;
                                {calcPatientsWithoutBlocks()}&nbsp;
                                {
                                  calcPatientsWithoutBlocks() === 1 ?
                                    `${t('scheduleTime.selectDateBlock.patientTitle').toLocaleLowerCase()}.`
                                    :
                                    t('scheduleTime.selectDateBlock.selectPatientsText5')
                                }
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      )
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={7} xs={24} className="select-hour-wrapper mt-lg-0 mt-xs-20">
              <Row justify="center" style={{ height: '100%', alignContent: 'space-between' }}>
                <Col lg={22} xs={22}>
                  <Row justify="center">
                    <Col lg={24} xs={24} className="mt-20">
                      <h3 className='branch-office-text mb-0'>
                        {t('scheduleTime.selectDateBlock.branchOfficeText')} {branchOffice.name}
                      </h3>
                    </Col>
                    <Col lg={24} xs={24}>
                      <h3>{t('scheduleTime.selectDateBlock.timeSelectionTitle')}</h3>
                    </Col>
                    <Col lg={24} xs={24} className="mt-lg-10 mt-xs-10 mb-lg-60 mb-xs-5">
                      {
                        patientsByBlock && Object.keys(patientsByBlock).map((block, index) => {
                          return (
                            <Row justify="space-between" key={index} className="mb-10">
                              <Col lg={23} sm={24}>
                                <Row>
                                  <Col lg={24} xs={24}>
                                    <p className="date mb-lg-0 lower-case">{formatDate(patientsByBlock[block][0]['date'])} - {block}</p>
                                  </Col>
                                  <Col lg={24} xs={24}>
                                    <Row>
                                      {
                                        patientsByBlock[block].map((patient, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <Col lg={23} xs={23} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Row>
                                                  <Col>
                                                    <p className="patient mb-0">
                                                      {t('scheduleTime.selectDateBlock.patientTitle')} {patient.number + 1}
                                                    </p>
                                                  </Col>
                                                  <Col className="info-icon-wrapper ml-10">
                                                    <Tooltip
                                                      color='#223A70'
                                                      title={
                                                        humanizeList(
                                                          patient.services.map(data => {
                                                            return data.battery_name
                                                          }),
                                                          { conjunction: 'y' }
                                                        )
                                                      }
                                                    >
                                                      <InfoCircleFilled />
                                                    </Tooltip>
                                                  </Col>
                                                  {
                                                    patient.documentNumber &&
                                                    <Col>
                                                      <p className="patient ml-5 mb-0">
                                                        {documentTypeName(patient.documentType)} {patient.documentType === 'rut' ? format(patient.documentNumber) : patient.documentNumber}
                                                      </p>
                                                    </Col>
                                                  }
                                                </Row>
                                              </Col>
                                              <Col lg={1} xs={1} className="mt-5">
                                                <Button
                                                  className="cmt-button secondary non-outline"
                                                  type='primary'
                                                  onClick={() => handleCancelPatient(patient.number)}
                                                >
                                                  <span className='icon-system-delete' />
                                                </Button>
                                              </Col>
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </Col>
                  </Row>
                </Col>
                <Col lg={22} md={22} sm={24} xs={24}>
                  <Row justify="end" className="mt-lg-90 mt-md-20 mt-sm-20 mt-xs-20 mb-20 next-button-wrapper">
                    <Col lg={10} xs={22}>
                      <Button
                        disabled={!canContinue}
                        onClick={handleChangeStateNext}
                        block
                        className={!canContinue ? 'cmt-button disabled' : 'cmt-button primary'}
                        type='primary'
                      >
                        Siguiente
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={24} sm={24} xs={24} className="select-hour-drawer-mobile pt-20 pb-20" ref={hourSelectionToHideWithSwitch}>
              <Row justify="center">
                <Col md={22} sm={22} xs={22} className="hour-selection-wrapper">
                  <h3 className="mb-5">{t('scheduleTime.selectDateBlock.timeSelectionTitle')}</h3>
                  <Button className="chevron-button" onClick={handleChangeChevron} style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={UpIconGray} width="24" height="14" alt="Up icon gray" />
                  </Button>
                </Col>
                <Col md={22} sm={22} xs={22}>
                  <Row justify="center" className="next-button-wrapper mt-md-10 mt-sm-10 mt-xs-10 mb-10">
                    <Button disabled={!canContinue} onClick={handleChangeStateNext} block>Siguiente</Button>
                  </Row>
                </Col>
                <Drawer className="drawer-patients-block" placement="bottom" onClose={onCloseDrawer} closable={false} open={isDrawerVisible}>
                  <Row justify="center" style={{ height: '100%', alignContent: 'space-between' }}>
                    <Col md={22} sm={22} xs={22}>
                      <Row justify="center">
                        <Col md={24} sm={24} xs={24} className="pt-20 hour-selection-wrapper">
                          <h3 className="mt-15 mb-15">Tu selección de horas:</h3>
                          <Button className="chevron-button" onClick={handleChangeChevron} style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={DownIconGray} width="24" height="14" alt="Down icon gray" />
                          </Button>
                        </Col>
                        <Col md={24} sm={24} xs={24} className="mt-xs-10 mb-xs-5">
                          {
                            patientsByBlock && Object.keys(patientsByBlock).map((block, index) => {
                              return (
                                <Row justify="space-between" key={index} className="mb-15">
                                  <Col md={24} sm={24} xs={24}>
                                    <Row>
                                      <Col md={24} sm={24} xs={24}>
                                        <p className="date mb-lg-10 mb-xs-0 lower-case">{formatDate(patientsByBlock[block][0]['date'])} - {block}</p>
                                      </Col>
                                      <Col md={24} sm={24} xs={24}>
                                        <Row>
                                          {
                                            patientsByBlock[block].map((patient, index) => {
                                              return (
                                                <React.Fragment key={index}>
                                                  <Col md={23} sm={23} xs={23} className="mb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Row>
                                                      <Col>
                                                        <p className="patient mb-0">
                                                          {t('scheduleTime.selectDateBlock.patientTitle')} {patient.number + 1}
                                                        </p>
                                                      </Col>
                                                      <Col className="info-icon-wrapper ml-10">
                                                        <Tooltip title={
                                                          patient.services.map(data => {
                                                            return `${data.exam}. `
                                                          })
                                                        }
                                                        >
                                                          <InfoCircleFilled />
                                                        </Tooltip>
                                                      </Col>
                                                      {
                                                        patient.documentNumber &&
                                                        <Col>
                                                          <p className="patient ml-5 mb-0">
                                                            {documentTypeName(patient.documentType)} {patient.documentType === 'rut' ? format(patient.documentNumber) : patient.documentNumber}
                                                          </p>
                                                        </Col>
                                                      }
                                                    </Row>
                                                  </Col>
                                                  <Col md={1} sm={1} xs={1} className="mt-5 mb-5">
                                                    <Button
                                                      className="close-button"
                                                      onClick={() => handleCancelPatient(patient.number)}
                                                    >
                                                      <span className="icon-system-delete" />
                                                    </Button>
                                                  </Col>
                                                </React.Fragment>
                                              )
                                            })
                                          }
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </Col>
                      </Row>
                    </Col>
                    {
                      cartState.patients && !cartState.patients.filter(item => item.block !== null && item.date !== null).length &&
                      <Col xs={23} className="pt-120 pb-120">
                        <h4 className="text-center">{t('scheduleTime.selectDateBlock.noTimeSelectedTitle')}</h4>
                      </Col>
                    }
                    <Col md={22} sm={22} xs={22}>
                      <Row justify="center" className="next-button-wrapper mt-md-10 mt-sm-10 mt-xs-10 mb-10">
                        <Button disabled={!canContinue} className={!canContinue ? 'disabled-next-button' : 'reservations-confirmation'} onClick={handleChangeStateNext} block>Siguiente</Button>
                      </Row>
                    </Col>
                  </Row>
                </Drawer>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin >
  )
}

export default SelectDateBlock
